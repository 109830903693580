import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';

export default class LabelGenerationApiService {
    public async getAssembleUnitLabel(unitLoadId: number, quantity: number): Promise<DataAccessResponse<string>> {
        const response: AxiosResponse<DataAccessResponse<string>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/labelgeneration/unit-load/${unitLoadId}/quantity/${quantity}`,
        });

        return new DataAccessResponseClass<string>(response).response;
    }

    public async getInboundLabelsContents(transactionId : number, quantity: number): Promise<DataAccessResponse<string>> {
        const response: AxiosResponse<DataAccessResponse<string>> = await axios({
            method: 'get',
            url: `${API_SERVICE_URL}/labelgeneration/inbound/transactionId/${transactionId}/quantity/${quantity}`,
        });

        return new DataAccessResponseClass<string>(response).response;
    }
}
