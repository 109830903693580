import LabelGenerationApiService from '@/services/api/LabelGenerationApiService';
import useLoading from '@/modules/floortrak/composables/useLoading';
import { useNotification } from '@/composable/useNotifications';
import { getTranslation } from '@/services/TranslationService';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';

export default class LabelGenerationService {
    private labelGenerationApiService = new LabelGenerationApiService();

    private async getLabelUrl(labelGenerationApiCallback: () => Promise<DataAccessResponse<string>>): Promise<string> {
        useLoading().setLoading(true);

        const { success, data } = await labelGenerationApiCallback();
        if (success) {
            if (data) {
                // arrowhead:// is needed for the mobiprint app
                const label = data.replace(/=/g, '_');
                const url = `arrowhead://x-callback-url/zplcode?code=${label}`;
                useLoading().setLoading(false);
                return url;
            }

            useNotification().showError(getTranslation('core.validation.unableToRetrieveLabelInformation'));
        }

        useLoading().setLoading(false);
        return '';
    }

    public async getAssembleUnitLoadLabelUrl(unitLoadId: number, quantity: number): Promise<string> {
        return this.getLabelUrl(async () => this.labelGenerationApiService.getAssembleUnitLabel(unitLoadId, quantity));
    }

    public async getInboundLabelsUrl(transactionId: number, quantity: number): Promise<string> {
        return this.getLabelUrl(async () => this.labelGenerationApiService.getInboundLabelsContents(transactionId, quantity));
    }
}
